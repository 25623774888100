





























import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import firebase from '@/plugins/firebase'
import { Device } from '@/models/device'
import DeviceItem from '@/components/manage/DeviceItem.vue'
import AuthStore from '@/store/AuthStore'
import LatLng from '@/types/LatLng'
import DeviceMarker from '@/components/common/DeviceMarker.vue'

declare const google: any
@Component<MonitorMapList>({
  components: {
    DeviceItem,
    DeviceMarker
  },
  mounted () {
    this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class MonitorMapList extends Vue {
  @Ref('map') map!: { $mapPromise: any }
  unsubscribe: firebase.Unsubscribe | null = null
  docs: firebase.firestore.QueryDocumentSnapshot<Device>[] = []
  bounded = false

  @Watch('selectedCompany')
  onChangeCompany (): void {
    this.subscribe()
  }

  @Watch('selectedGroup')
  onChangeGroup (): void {
    this.subscribe()
  }

  get style (): string {
    let offset = 180
    if (this.$vuetify.breakpoint.xs) offset -= 74
    const height = this.$vuetify.breakpoint.height - offset
    return `height: ${height}px`
  }

  get selectedCompany (): unknown | null {
    return AuthStore.selectedCompany
  }

  get selectedGroup (): unknown | null {
    return AuthStore.selectedGroup
  }

  get deviceRef (): firebase.firestore.Query<Device> {
    return AuthStore.deviceRef
  }

  get filteredDocs (): firebase.firestore.QueryDocumentSnapshot<Device>[] {
    return this.docs.filter(doc => {
      const item = doc.data()
      return item.lastSecond.latitude && item.lastSecond.longitude
    })
  }

  get items (): Device[] {
    return this.filteredDocs.map(doc => doc.data())
  }

  get markers (): LatLng[] {
    return this.items.map(item => {
      return { lat: item.lastSecond.latitude, lng: item.lastSecond.longitude }
    })
  }

  get firstMarker (): LatLng {
    return this.markers[0]
  }

  get lastMarker (): LatLng {
    return this.markers[this.markers.length - 1]
  }

  subscribe (): void {
    this.unsubscribe = this.deviceRef.limit(100).onSnapshot(sn => {
      this.docs = sn.docs
      this.$nextTick(() => {
        this.fitBounds()
      })
    })
  }

  async fitBounds (): Promise<void> {
    if (!this.items.length) return
    if (this.bounded) return

    const map = await this.map.$mapPromise

    const bounds = new google.maps.LatLngBounds()
    this.markers.forEach(v => {
      bounds.extend(v)
    })
    map.fitBounds(bounds)
    this.bounded = true
  }
}
