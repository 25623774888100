






























import { Component, Vue } from 'vue-property-decorator'
import MonitorMapList from '@/components/monitor/MonitorMapList.vue'
import CompanySelect from '@/components/manage/CompanySelect.vue'
import GroupSelect from '@/components/manage/GroupSelect.vue'
import firebase from '@/plugins/firebase'
import { Company } from '@/models/company'
import AuthStore from '@/store/AuthStore'
import CompanyNotFound from '@/components/common/CompanyNotFound.vue'

@Component<MonitorMapIndex>({
  components: { MonitorMapList, CompanySelect, GroupSelect, CompanyNotFound }
})
export default class MonitorMapIndex extends Vue {
  company: firebase.firestore.DocumentSnapshot<Company> | null = null

  get existsCompany (): boolean {
    return !!AuthStore.companies.length
  }

  get existsGroup (): boolean {
    return !!AuthStore.groups.length
  }

  get isCompanyAdmin (): boolean {
    return AuthStore.isCompanyAdmin
  }
}
